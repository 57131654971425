import React from 'react';
import Tile from './Tile';
import './GameBoard.css';

const indices = [...Array(30).keys()]

const GameBoard = ({
    currentGuess,
    pastGuesses,
    solution
}) => {

    const letterMap = [...pastGuesses, currentGuess].join('');
    const solutionMap = solution.repeat(6);
    return (
        <div
            className="gameboard"
        >
            {indices.map((i) => (
                <Tile
                    key={i}
                    letter={letterMap[i]}
                    solutionLetter={solutionMap[i]}
                    solution={solution}
                    showSolution={i / 5 < pastGuesses.length}
                />
            ))}
        </div>
    );
};
export default GameBoard;
