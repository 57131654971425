import words from './words';


const useSolution = () => {
    const start = new Date('2022-02-01T08:00:00Z');
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const index = Math.floor(Math.abs((today - start) / oneDay));
    return words[index] || 'megan';
};

export default useSolution;
