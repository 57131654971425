export default [
    'poopy',
    'stink',
    'megan',
    'butts',
    'olive',
    'sarah',
    'farts',
    'swift',
    'bagel',
    'toots',
    'queen',
    'bidet',
    'shart',
    'burps',
    'sleep',
    'shits',
    'pizza',
    'lunch',
    'gross',
    'sweet',
    'sweat',
    'slurp',
    'worms',
    'throb',
    'pants',
    'balls',
    'shots',
    'wines',
    'ratty',
    'whack',
    'satin',
    'rashy',
    'spoon',
    'yeets',
    'bloat',
    'smang',
    'seals',
    'milky',
    'meats',
    'penis',
    'smoke',
    'chips',
    'xxxxx',
    'xxxxx', // mar 16
    'teets',
    'hairy',
    'cooch',
    'spunk',
    'scott',
    'mulva',
    'knobs',
    'scone',
    'cream',
    'moist',
    'xxxxx',
    'xxxxx',
    'xxxxx',
    'xxxxx',
    'xxxxx', // mar 31
    'walks',
    'manic',
    'naked',
    'nasty',
    'crepe',
    'decaf',
    'booze',
    'phone',
    'trope',
    'poker',
    'snoop',
    'snore',
    'nosey',
    'smash',
    'bangs',
    'grape',
    'plant',
    'mango',
    'mochi',
    'busty',
    'spams',
    'soups', // apr 22
    'xxxxx',
    'xxxxx',
    'xxxxx',
    'xxxxx',
    'rails', // apr 27
    'waltz',
    'beans',
    'rainy',
    'mouse',
    'groin',
    'plows',
    'tango',
    'pearl',
    'lager',
    'ninja',
    'query',
    'spork',
    'pinot',
    'shuck',
    'vodka',
    'clams',
    'pooch',
    'poppy', // may 15
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'xxxxx', // may 23
    'clown',
    'cloth',
    'shelf',
    'space',
    'shows',
    'karen',
    'texas',
    'xxxxx',
    'xxxxx', // june 1
    'grump',
    'busty',
    'plops',
    'filet',
    'skunk',
    'glugs',
    'lover',
    'taylor',
    'chest',
    'erupt',
    'chugs',
    'swift',
    'aioli',
    'pudge',
    'fudge', // june 16
    '',
    '',
    'tampa', // june 19th
    'poppy',
    'brave',
    'apple',
    'beach',
    'funky',
    'stank',
    'drink',
    'wines',
    'floor',
    'drunk',
    'cigar',
    'snack',
    'dress'
];