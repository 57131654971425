import logo from './logo.svg';
import './App.css';
import GameBoard from './GameBoard';
import { useEffect, useState } from 'react';
import useSolution from './useSolution';

const alphabet = 'abcdefghijklmnopqrstuvwxyz';

function App() {

  const [currentGuess, setCurrentGuess] = useState('');
  const [pastGuesses, setPastGuesses] = useState([]);

  const solution = useSolution();

  const onLetter = (letter) => {
    if (currentGuess.length < 5) {
      setCurrentGuess(currentGuess.concat(letter))
    }
  };

  const onSubmit = () => {
    if (currentGuess.length === 5) {
      setPastGuesses([...pastGuesses, currentGuess])
      setCurrentGuess('');
    }
  };

  const onBackspace = () => {
    if (currentGuess.length > 0) {
      setCurrentGuess(currentGuess.slice(0, currentGuess.length - 1))
    }
  };

  useEffect(() => {
    const handleKeyDown = ({key, code, keyCode}) => {
      if (code === 'Backspace') {
        onBackspace();
      } else if (code === 'Enter') {
        onSubmit();
      } else if (alphabet.includes(key.toLowerCase())) {
        onLetter(key.toLowerCase());
      }
      
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  return (
    <div className="App">
      <header>
        Megurdle :)
      </header>
      <input 
        type="text"
        value={currentGuess}
        onChange={() => {}}
        placeholder="Type here on mobile"
      />     
      <GameBoard 
        currentGuess={currentGuess}
        pastGuesses={pastGuesses}
        solution={solution}
      /> 
    </div>
  );
}

export default App;
