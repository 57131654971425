import React from 'react';
import './Tile.css'

const getStatus = (letter, solutionLetter, solution) => {
    if (letter === solutionLetter) {
        return 'green'
    }

    if (solution.includes(letter)) {
        return 'yellow'
    }

    return 'grey'
};

const Tile = ({
    letter,
    solutionLetter,
    solution,
    showSolution,
}) => {
    const solutionClass =  showSolution ? getStatus(letter, solutionLetter, solution) : '';
    
    return (
        <div 
            className={`tile ${solutionClass}`}
        >
            {letter}
        </div>
    );
}

export default Tile;
